/* eslint-disable react-hooks/rules-of-hooks */
import { gql, useQuery } from '@apollo/client';
import BigNumber from 'bignumber.js';

import { ZERO } from 'lib/consts';

interface Params {
  value: string;
  exchangeRate?: string | null;
  accuracy?: number;
  accuracyUsd?: number;
  decimals?: string | null;
}

export default function getCurrencyValue({
  value,
  accuracy,
  accuracyUsd,
  decimals,
  exchangeRate,
}: Params) {
  const { data } = useQuery(
    gql`
      query token {
        tokens(
          where: { id_in: ["0xe81ca6eb09bd7d28ebc6362cd531d44528dcbf0d"] }
          orderBy: tradeVolumeUSD
          orderDirection: desc
        ) {
          id
          symbol
          name
          derivedETH
          derivedUSD
          tradeVolumeUSD
          totalTransactions
          totalLiquidity
        }
      }
    `,
    {
      skip: !exchangeRate,
    },
  );

  const valueCurr = BigNumber(value).div(
    BigNumber(10 ** Number(decimals || '18')),
  );
  const valueResult = accuracy ?
    valueCurr.dp(accuracy).toFormat() :
    valueCurr.toFormat();

  let usdResult: string | undefined;
  let usdBn = ZERO;

  if (exchangeRate) {
    const exchangeRateBn = new BigNumber(
      Number(data?.tokens[0]?.derivedUSD).toFixed(2),
    );
    usdBn = valueCurr.times(exchangeRateBn);
    if (accuracyUsd && !usdBn.isEqualTo(0)) {
      const usdBnDp = usdBn.dp(accuracyUsd);
      usdResult = usdBnDp.isEqualTo(0) ?
        usdBn.precision(accuracyUsd).toFormat() :
        usdBnDp.toFormat();
    } else {
      usdResult = usdBn.toFormat();
    }
  }

  return { valueStr: valueResult, usd: usdResult, usdBn };
}
